import fromUnixTime from "date-fns/fromUnixTime";

export const formatEvents = (events) => {
  return events.map((event) => {
    const { location } = event;
    const street = location.address_lines.join(" ");
    const city = location.locality;
    const state = location.region;
    const zip = location.postal_code;

    return {
      title: event.title,
      startDate: fromUnixTime(event.timeslots[0].start_date),
      endDate: fromUnixTime(event.timeslots[0].end_date),
      location: event.is_virtual
        ? "Virtual"
        : `${street}, ${city}, ${state} ${zip}`,
      link: event.browser_url,
    };
  });
};
