import { Route, BrowserRouter as Router } from "react-router-dom";

import { Contact } from "./components/Contact.jsx";
import { Elections } from "./components/vote/UpcomingElections.jsx";
import { EventCalendar } from "./components/calendar/Calendar.jsx";
import { Home } from "./components/Home.jsx";
import Navbar from "./components/navbar/Navbar.jsx";
import { News } from "./components/news/News";
import { PollingPlace } from "./components/vote/PollingPlace.jsx";
import { RankedVoting } from "./components/vote/RankedVoting.jsx";
import React from "react";
import { Registration } from "./components/vote/Registration.jsx";
import { Representatives } from "./components/vote/Representatives/index.jsx";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    fontFamily: [
      "barlowcondensed_semibold",
      "open_sansbold",
      "opensans_lightitalic",
      "opensans_extrabold",
      "bitter_italic",
      "bitter_bold",
      "bitter_regular",
      "din",
      "din_condensed",
    ].join(","),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <Navbar />
          <Route exact path="/" component={Home} />
          <Route exact path="/calendar" component={EventCalendar} />
          <Route exact path="/pollingplace" component={PollingPlace} />
          <Route exact path="/rankedvoting" component={RankedVoting} />
          <Route exact path="/registration" component={Registration} />
          <Route exact path="/representatives" component={Representatives} />
          <Route exact path="/elections" component={Elections} />
          <Route exact path="/news" component={News} />
          <Route exact path="/contact" component={Contact} />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
