import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  MonthView,
  Appointments,
  AppointmentTooltip,
  Toolbar,
  DateNavigator,
} from "@devexpress/dx-react-scheduler-material-ui";
import axios from "axios";
import { formatEvents } from "../../utils/eventDataHelpers";
import Appointment from "./Appointment";
import Content from "./Content";

export const EventCalendar = () => {
  const [events, setEvents] = useState([]);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axios.get(
          "https://api.mobilize.us/v1/organizations/159/events?timeslot_start=gte_now&zipcode=10024&max_dist=50&per_page=1000"
        );
        const formattedEvents = formatEvents(data.data);
        setEvents(formattedEvents);
      } catch (error) {
        setIsError(true);
      }
    }
    fetchData();
  }, []);

  return (
    <Paper id="calendar">
      {isError && <div>There was a problem loading the event calendar</div>}
      {events.length ? (
        <Scheduler data={events}>
          <ViewState />
          <MonthView />
          <Toolbar />
          <DateNavigator />
          <Appointments appointmentComponent={Appointment} />
          <AppointmentTooltip contentComponent={Content} showCloseButton />
        </Scheduler>
      ) : (
        <div>The events calendar is loading</div>
      )}
    </Paper>
  );
};
